/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.loading {
  pointer-events: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background-color: rgba(255, 255, 255, .6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  // &:after {
  //   content: '';
  //   position: absolute;
  //   width: 50px;
  //   height: 50px;
  //   left: calc(50% - 25px);
  //   top:  calc(50% - 25px);
  //   border-top: 5px solid rgba(#1875BC, 0.2);
  //   border-right: 5px solid rgba(#1875BC, 0.2);
  //   border-bottom: 5px solid rgba(#1875BC, 0.2);
  //   border-left: 5px solid #1875BC;
  //   border-radius: 50%;
  //   transform: translateZ(0);
  //   animation: loading-animation 0.8s infinite linear;
  //   z-index: 2;
  // }
}
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.thumb-example {
  height: 480px;
  background-color: #000;
}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;

    &.slide-1 {
      background-image:url('/images/example/1.jpg');
    }
    &.slide-2 {
      background-image:url('/images/example/2.jpg');
    }
    &.slide-3 {
      background-image:url('/images/example/4.jpg');
    }
    &.slide-4 {
      background-image:url('/images/example/5.jpg');
    }
    &.slide-5 {
      background-image:url('/images/example/6.jpg');
    }
  }

  &.gallery-top {
    height: 80%;
    width: 100%;
  }
  &.gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 20px 0;
    @media screen and (max-width:600px){
      padding: 0px;
      display: none;
    }
  }
  &.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}

.placeholder:after{
  animation: shine 1.2s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  left: -0%;
  width: 280px;
  height: 100%;
  top: 0;
  opacity: 0;
  transform: rotate(0deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(90deg, rgba(255,255,255,0) 31%, rgba(255,255,255,0.7035189075630253) 44%, rgba(255,255,255,0.6979166666666667) 51%, rgba(255,255,255,0) 67%);
}

.placeholder:active:after {
  opacity: 0;
}

@keyframes shine{
  0% {
    opacity: 1;
    left: -70%;
    transition-property: left, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: cubic-bezier(0.110, 0.495, 0.590, 0.875);
  }
  100% {
    opacity: 0;
    left: 70%;
    transition-property: left, opacity;
  }
}
