


















































































































































































































































































































.filters-single {
    .active {}
}
.filter-outer {
    @media screen and (max-width: 767px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

        &.open {
            max-height: 2000px;
        }
    }
}

.filter-group {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

    .filter-wrapper.open & {
        opacity: 1;
        max-height: 1000px;
    }
}

.filter-wrapper {
    h3 {
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            pointer-events: none;
            transition: transform 0.2s ease;
            path {
                stroke: currentColor;
            }
        }
    }
    &.open {
        h3 {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
