// General stuff

.prev, .next{
  position: absolute;
  top: 50%;
  width: var(--swiper-navigation-size);
  border-radius:999px;
  height: var(--swiper-navigation-size);
  margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000;
}

.next, .swiper-container-rtl .prev{
  right: 10px;
  left: auto;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  left: 10px;
  right: auto;
}

.prev.swiper-button-disabled, .next.swiper-button-disabled{
  opacity: 0;
  cursor: auto;
  display: none;
  pointer-events: none;
}

.next:after, .swiper-container-rtl .prev:after{
  content: 'next';
    margin-right: -4px;
}

.prev:after, .swiper-container-ltr .next:after{
  content: 'prev';
  margin-left: -4px;
}

.prev:after, .next:after{
  font-family: swiper-icons;
  font-size: 20px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
}

.bm-burger-bars{
  background-color: #fff !important;
}

.line-style{
  height: 10% !important;
}

.bm-burger-button{
      position: relative !important;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
}

.bm-cross-button{
  top:30px !important;
  right:20px !important;
  height: 34px;

width: 34px;
}

.bm-cross{
  width: 2px !important;
    height: 34px !important;
}
